import React, { useEffect, useState } from "react"
import firebase from 'firebase/app'
import 'firebase/firestore'
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecipeCard from '../components/RecipeCard'
import FirebaseComponent from '../components/Firebase'

const IndexPage = () => {
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        firebase.firestore(firebase.app('frontapp')).collection('recipes').limit(21).get()
            .then((recipesSnapshot) => {
                const latestRecipes = [];
                recipesSnapshot.forEach((doc) => {
                    latestRecipes.push(doc.data());
                });
                if (recipes.length === 0) {
                    setRecipes(latestRecipes);
                }
            }).catch((error) => {
                console.log(error);
            });
    }, [recipes]);

    const getRecipeCards = () => {
        return recipes.map((recipeData) => {
            return <RecipeCard data={recipeData} />
        })
    }

    return (
        <FirebaseComponent appName="frontapp">
            <Layout>
                <SEO title="Simple delicious food recipes" />
                <header className="masthead text-white text-center">
                    <div className="overlay" />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 mx-auto">
                                <h1 className="mb-4">Simple to follow delicious recipes with step by step visual instructions</h1>
                            </div>
                        </div>
                        <section className="features-icons text-center">
                            <div className="container">
                                <div className="row white-bg">
                                    <div className="col-lg-4">
                                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                            <div className="features-icons-icon d-flex">
                                                <i className="icon-screen-desktop m-auto" />
                                            </div>
                                            <h3>Choose a recipe</h3>
                                            <p className="lead mb-0">Select a recipe from a collection of easy to prepare recipes.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                            <div className="features-icons-icon d-flex">
                                                <i className="icon-layers m-auto" />
                                            </div>
                                            <h3>Follow the directions</h3>
                                            <p className="lead mb-0">Follow the minimal simplified steps in a recipe to prepare the meal.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                                            <div className="features-icons-icon d-flex">
                                                <i className="icon-check m-auto" />
                                            </div>
                                            <h3>Enjoy the food</h3>
                                            <p className="lead mb-0">Enjoy your delicious and healthy self prepared meal!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </header>

                <section className="bg-light text-center section-featured-recipes">
                    <div className="container">
                        <div>
                            <h3 className="featured-heading">Featured Recipes</h3>
                            <h4 className="featured-sub-heading">Quick and easy recipes to cook meals with visuals for each step.</h4>
                        </div>
                        <div className="row white-bg">
                            {getRecipeCards()}
                        </div>
                    </div>
                </section>
            </Layout>
        </FirebaseComponent>
    );
}

export default React.memo(IndexPage)
