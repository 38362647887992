import React from 'react';
import './RecipeCard.css';
const RecipeCard = ({ data }) => {
    return (
        <a href={`/${data.pageUrl.trim()}`} className="card-link-wrap col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-5">
            <div className="RecipeCard">
            <div className="recipe-card-wrapper">
                <div className="recipe-card-image-wrapper">
                    <img src={data.mainImageUrl} />
                    <h5 className="recipe-card-title">{data.title}</h5>
                </div>
                <div className="row recipe-summary">
                    <div className="col-6 summary-col ">
                        <h6>Serves</h6>
                        {data.numberOfServings}
                    </div>
                    <div className="col-6 summary-col ">
                        <h6>Cooking Time</h6>
                        {data.totalCookingTime}
                </div>
                    <div className="col-6 summary-col bottom-column-first">
                        <h6>Total Steps</h6>
                    {data.steps.length}
                </div>
                    <div className="col-6 summary-col bottom-column-second">
                        <h6>Difficulty</h6>
                    Easy
                </div>
                </div>
            </div>
        </div>
        </a>);
};

export default RecipeCard;